ul.bigList > li {
  margin-bottom: 1.1em;
}

ul.bigList > li > strong {
  font-size: 1.2em;
}

ul.bigList ol {
  margin-top: 1.3em;
}

svg > g > g:last-child { pointer-events: none }